<template>
  <div class="bg bg7">
    <div class="class--con">
      <class-but i-s-throw-out @backOfff="backOfff" @forward="forward"/>
      <div class="colour">学生成长回顾（{{ courseStudentGrowUpListIndex + 1 }}/{{ courseStudentGrowUpList.length }}）</div>
      <div class="class--container" v-if="courseStudentGrowUpList.length > 0">
        <div class="user_studentName">{{ courseStudentGrowUpList[courseStudentGrowUpListIndex].studentName }}</div>
        <div class="user_item">
          <div>
            <div class="user_img">
              <img :src="courseStudentGrowUpList[courseStudentGrowUpListIndex].laterWorks.imgUrl" alt="">
            </div>
            <div>创作时间：{{courseStudentGrowUpList[courseStudentGrowUpListIndex].laterWorks.createTime.split(' ')[0].split('-')[0]}}年
              {{courseStudentGrowUpList[courseStudentGrowUpListIndex].laterWorks.createTime.split(' ')[0].split('-')[1]}}月
              {{courseStudentGrowUpList[courseStudentGrowUpListIndex].laterWorks.createTime.split(' ')[0].split('-')[2]}}日
            </div>
          </div>
          <div>
            <div class="user_text">
              {{courseStudentGrowUpList[courseStudentGrowUpListIndex].laterWorks.teacherComment}}
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";

import service from "@/utils/axios";

export default {
  name: "ninePage",
  components: {
    ClassBut,
  },
  data() {
    return {
      value1: 0,
      courseStudentGrowUpList: [],
      courseStudentGrowUpListIndex: 0
    }
  },
  created() {
    this.getApicourseStudentGrowUp()
  },
  methods: {
    forward() {
      if (this.courseStudentGrowUpListIndex + 1 < this.courseStudentGrowUpList.length) {
        this.courseStudentGrowUpListIndex++
      } else if (this.courseStudentGrowUpListIndex + 1 === this.courseStudentGrowUpList.length) {
        this.$router.push({
          name: 'PageFourteenth',
          query: this.$route.query,
        })
      }
    },
    async getApicourseStudentGrowUp() {
      let res = await service.post('/dwart/an_teaching/hbk/v1/search/courseStudentGrowUp', {
        classId: this.$route.query.classId,
      })
      this.courseStudentGrowUpList = res
      if (res.length === 0) {
        await this.$router.replace({
          name: 'PageTenth',
          query: this.$route.query,
        })
      }
    },
    backOfff() {
      if (this.courseStudentGrowUpListIndex > 0) {
        this.courseStudentGrowUpListIndex--
      } else {
        this.$router.back(-1)
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import "reportClass.less";

.class--container {
  .user_studentName{
    width: 168px;
    height: 60px;
    background: #FF6640;
    border-radius: 35px;
    font-size: 39px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    line-height: 60px;
    margin-left:  109px;
    margin-bottom: 20px;
  }
  .user_item{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    & > div{
      display: flex;
      align-items: center;
      flex-direction: column;
      color: #FF6640;
      font-size: 24px;
      font-weight: bold;
      width: 771px;
    }
    .user_img{
      width: 771px;
      height: 630px;
      background: #D8D8D8;
      border-radius: 18px;
      border: 7px solid #FFC94D;
      img{
        border-radius: 18px;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .user_text{
      width: 771px;
      height: 630px;
      color: #FF6640;
      font-size: 26px;
      overflow: auto;
    }
  }
}

.colour {
  color: #2B3654;
  font-size: 42px;
  font-weight: bold;
  margin: 35px 90px;
}


::-webkit-scrollbar {
  display: none;
}

</style>
